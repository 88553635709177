<template>
	<div id="doctorList">
		<!-- 标题 -->
		<div class="labelBox">
			<div class="title">痒疹问卷管理</div>
			<div>
				<el-select v-model="type" placeholder="请选择" style="margin-right: 10px;" size="medium" clearable>
					<el-option v-for="item in taskTypeList" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
				</el-select>
				<el-button type="warning" size="medium" @click="search">筛选</el-button>
				<el-divider direction="vertical"></el-divider>
				<el-button type="primary" size="medium" @click="add">添加问卷</el-button></div>
		</div>

		<!-- 问卷列表 -->
		<el-table :data="list" style="width: 100%">
			<el-table-column prop="id" label="ID" width="50"></el-table-column>
			<el-table-column prop="name" label="问卷名称" width="400"></el-table-column>
			<el-table-column prop="url" label="链接" width="400"></el-table-column>
			<el-table-column prop="typeName" label="问卷类型"></el-table-column>
			<el-table-column label="关节炎专属">
				<template slot-scope="scope">
					<el-tag type="success" size="small" v-if="scope.row.isPsa == '1'">是</el-tag>
					<el-tag type="danger" size="small" v-else>否</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<el-tag type="success" size="small" v-if="scope.row.open === 1">展示</el-tag>
					<el-tag type="danger" size="small" v-else>隐藏</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="order" label="排序"></el-table-column>
			<el-table-column prop="createTime" label="添加时间"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
					<el-divider direction="vertical"></el-divider>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)"><el-button type="text" slot="reference" style="color:#F56C6C">删除</el-button></el-popconfirm>
				</template>
			</el-table-column>
		</el-table>

		<!-- 添加问卷弹窗 -->
		<el-dialog :title="editTitle" :visible.sync="editModal" width="50%">
			<div class="modalBox">
				<el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px" class="demo-ruleForm">
					<el-form-item label="问卷名称" prop="name"><el-input v-model="editForm.name"></el-input></el-form-item>
					<el-form-item label="URL" prop="url"><el-input v-model="editForm.url"></el-input></el-form-item>
					<el-form-item label="类型" prop="type">
						<el-select v-model="editForm.type" placeholder="请选择">
							<el-option v-for="item in taskTypeList" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="关节炎患者专属" prop="open">
						<el-radio v-model="editForm.isPsa" label="1">是</el-radio>
						<el-radio v-model="editForm.isPsa" label="0">否</el-radio>
					</el-form-item>
					<el-form-item label="排序" prop="order">
						<el-input-number :min="0" v-model="editForm.order"></el-input-number>
						<p>数字大的排在前面 例如 99 > 50</p>
					</el-form-item>
					<el-form-item label="是否开启" prop="open">
						<el-radio v-model="editForm.open" :label="1">开启</el-radio>
						<el-radio v-model="editForm.open" :label="0">关闭</el-radio>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editModal = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'setting',
	data() {
		return {
			id: '',
			list: [],
			type:'',
			changeData: [],
			taskTypeList: [],
			editModal: false,
			editTitle: '',
			editForm: {
				name: '',
				appid: '',
				portId: ''
			},
			rules: {
				name: [
					{
						required: true,
						message: '请填写任务名称',
						trigger: 'blur'
					}
				],
				url: [
					{
						required: true,
						message: '请填写链接',
						trigger: 'blur'
					}
				],
				order: [
					{
						required: true,
						message: '请填写排序',
						trigger: 'blur'
					}
				],
				type: [
					{
						required: true,
						message: '请选择问卷类型',
						trigger: 'change'
					}
				],
				isPsa: [
					{
						required: true,
						message: '请选择是否是关节炎患者专属',
						trigger: 'change'
					}
				],
				open: [
					{
						required: true,
						message: '请选择是否开启',
						trigger: 'change'
					}
				]
			}
		};
	},

	created() {
		this.getList();
		this.getTaskTypeList();
	},

	methods: {
		getTaskTypeList() {
			this.$http.post('/yz/taskTypeList', {}).then(res => {
				if (res.data.code == 1) {
					this.taskTypeList = res.data.data;
				}
			});
		},

		add() {
			this.editForm = {};
			this.id = '';
			this.editTitle = '添加问卷';
			this.editModal = true;
		},

		edit(id) {
			this.id = id;
			this.editTitle = '编辑问卷';
			this.$http
				.post('/yz/taskView', {
					id: this.id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.editForm = res.data.data;
						this.editModal = true;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},

		submitForm() {
			this.$refs['editForm'].validate(valid => {
				if (valid) {
					var data = {
						name: this.editForm.name,
						url: this.editForm.url,
						type: this.editForm.type,
						order: this.editForm.order,
						open: this.editForm.open,
						isPsa: this.editForm.isPsa,
					};
					if (this.id) {
						data.id = this.id;
					}
					this.$http.post('/yz/taskEdit', data).then(res => {
						if (res.data.code == 1) {
							this.editModal = false;
							this.getList();
							this.$message.success(res.data.msg);
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}
			});
		},

		del(id) {
			this.$http
				.post('/yz/taskDel', {
					id: id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.getList();
						this.$message.success(res.data.msg);
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		
		search(){
			this.list = []
			this.getList()
		},
		
		getList() {
			this.$http.post('/yz/taskList', {
				type:this.type
			}).then(res => {
				if (res.data.code == 1) {
					this.list = res.data.data;
				}
			});
		}
	}
};
</script>

<style scoped>
#doctorList {
	padding: 30px;
	background: #fff;
}

.modalBox {
	padding-right: 100px;
	/* overflow:scroll; */
	overflow-y: auto;
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: auto;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 178px;
	height: auto;
	display: block;
}

.paginationBox {
	margin: 20px 0;
	text-align: right;
}
</style>
